<template>
  <div class="col-lg-12 col-xl-6">
    <div class="card bg-light border-0 rounded-4 text-center h-100" :class="{'disabled': event_item.channels.length == 0 || (!(event_item.channels[0].status && event_item.channels[0].active))}">
      <div v-if="event_item.channels.length != 0 && event_item.channels[0].status && event_item.channels[0].active" class="badge text-bg-danger fs-6 rounded-pill px-4 py-2 mb-0">⦿ LIVE</div>
      <div v-else class="badge bg-white text-secondary fs-6 rounded-pill px-4 py-2 mb-0">⦿ OFFLINE</div>
      <a class="card-body p-sm-5 pt-6 text-decoration-none shine" @click="toggleIsClicked">
        <div class="row align-items-center justify-content-between">
          <div class="col-4">
            <img class="img-fluid rounded flag-img" alt="img" :src="computedImageUrl(event_item.firstMatcher)" @error="loadBackupImage">
            <small class="d-block mt-2 text-uppercase fs-6 fw-regular">{{event_item.firstMatcher}}</small>
          </div>
          <!--/col(left)-->
          <div class="col-sm-4 col-3">
            <img class="img-fluid" src="../assets/images/play-icon.svg" alt="icon">
          </div>
          <!--/col(center)-->
          <div class="col-4">
            <img class="img-fluid rounded flag-img" alt="img" :src="computedImageUrl(event_item.secondMatcher)" @error="loadBackupImage">
            <small class="d-block mt-2 text-uppercase fs-6 fw-regular">{{event_item.secondMatcher}}</small>
          </div>
          <!--/col(right)-->
        </div>
        <!--/row-->
        <hr class="my-sm-4">
        <p class="mb-0">{{event_item.league}}</p>
      </a>
      <!--/card-body-->
    </div>
    <!--/card-->
  </div>
  <!--/col()-->
</template>
  
<script>
  import {defineComponent} from "vue";
  import AuthService from "@/services/auth-service";
  
  export default defineComponent({
    name: "EventItem",
    props : ["event_item", 'dynamicData'],
    data() {
      return {
        cdnUrl: '',
        backupImage: '/images/Flag-01.jpeg'
      };
    },
    methods: {
      computedImageUrl(matcherName) {
        const processedMatcher = matcherName.replace(/\s+/g, '').toLowerCase();
        const imageUrl = `${this.cdnUrl}${processedMatcher}.png`;
        return imageUrl;
      },
      loadBackupImage(event) {
        // If the main image fails to load, set the src to the backup image
        event.target.src = this.backupImage;
      },
      toggleIsClicked: function () {
        if (!this.event_item.channels[0].status || !this.event_item.channels[0].active || this.dynamicData.edgeAddress === "" || this.dynamicData.edgeAddress === undefined) {
          //VueSimpleAlert.alert("Channel is not available at the moment", "Channel error")
        } else {
          localStorage.setItem('eventTitle', this.event_item.firstMatcher + " VS " + this.event_item.secondMatcher)
          localStorage.setItem('channel', JSON.stringify(this.event_item.channels[0]))
          this.$router.push('/player')
        }
      },
    },
    beforeMount() {
      if (localStorage.getItem('cdnUrl')) this.cdnUrl = JSON.parse(localStorage.getItem('cdnUrl'))
    },
  })
</script>

<style>
@import '../assets/css.css';
</style>