<template>
  <Header></Header>
  <div class="main-height">
     <section id="error" class="mt-4">
      <div class="container-fluid">
        <div v-if="error !== null && error !== ''" class="alert alert-secondary py-2 user-notification px-0" role="alert">
          <div class="d-flex flex-column beta- v px-0 align-items-center">
            <h5 class="mb-0 d-flex"><img class="info-img align-self-center" src="../assets/info.svg" alt="">{{ error }}</h5>
          </div>
        </div>
     </div>
     </section>
    <section id="content">
      <div class="container-fluid">
        <div v-if="isLoading" class="row gy-5">
          <EventItemSkeleton></EventItemSkeleton>
          <template v-for="event in this.eventsList" v-bind:key="event.eventId">
            <EventItemSkeleton></EventItemSkeleton>
          </template>
        </div>
        <div v-else class="row gy-5">
          <template v-for="event in this.eventsList" v-bind:key="event.eventId">
            <EventItem v-bind:event_item="event" v-bind:dynamicData="dynamicData"></EventItem>
          </template>
        </div>
        <!--/row-->
      </div>
      <!--/container-fluid-->
    </section>
    <!--/content-->
  </div>
  <Footer></Footer>
</template>

<script>
import {defineComponent} from 'vue'
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import EventItem from '@/components/EventItem.vue'
import EventItemSkeleton from '@/components/EventItemSkeleton.vue'
import AuthService from "@/services/auth-service";
import UsersService from "@/services/users-service";
import LeagueService from "@/services/league-service";

export default defineComponent({
  name: "Events",
  components: {
    EventItem,
    EventItemSkeleton,
    Footer,
    Header,
  },
  data() {
    return {
      isLoading: true,
      interval: null,
      eventsList: [],
      userPlan: null,
      userProfile: null,
      planEndDate: '',
      error: null,
      scrolled: false,
      leaguesList: [],
      dynamicData: [],
    }
  },
  methods: {
    goEvents () {
      this.$router.push('/events')
    },
    doAccount() {
      this.$router.push('/account')
    },
    getUserLatest: function () {
      this.loadDynamicData()
      UsersService.getUserLatest().then((newUserLatest) => {
        if (newUserLatest) {
          this.compareUserLatest(newUserLatest)
        }
      })
    },
    compareUserLatest: function (newUserLatest) {
      if (localStorage.getItem('userLatest') && localStorage.getItem('userLatest') !== JSON.stringify(newUserLatest)) {
        const oldUserLatest = JSON.parse(localStorage.getItem('userLatest'))
        // compare user latest
        if (oldUserLatest.loginId !== newUserLatest.loginId) {
          AuthService.signOut().then(() => {
            localStorage.setItem('sessionExpired', "true")
            this.$router.push('/signin')
          })
        }
        if (oldUserLatest.userProfile != newUserLatest.userProfile
            || oldUserLatest.userPlans != newUserLatest.userPlans) {
          // update user profile
          UsersService.getUserProfile().then(() => {
            if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
            if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
            if ((this.userProfile.planActive == undefined && this.userProfile.planStatus == undefined) || this.userProfile.planEndDate < Date.now()) {
              // refunded user, logout user with message
              AuthService.signOut().then(() => {
                localStorage.setItem('planExpired', "true")
                this.$router.push('/signin')
              })
            }
            if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
          })
        }
        if (oldUserLatest.payments != newUserLatest.payments) {
            // update user payments
        }
        if (oldUserLatest.statics !== newUserLatest.statics) {
          UsersService.getUserStaticData()
        }
        if (oldUserLatest.channels != newUserLatest.channels) {
          // update leagues
          LeagueService.getLeagueList().then(() => {
            if (localStorage.getItem('leagueList')) {
              this.leaguesList = JSON.parse(localStorage.getItem('leagueList'))
              this.eventsList = []
              this.leaguesList.forEach(league => {
                // Check if the leagueId matches
                if (league.application === "procricket") {
                  league.events.forEach(event => {
                    this.eventsList.push(event);
                  });
                }
              });
            }
          })
        }
      }

      this.error = newUserLatest.errorText
      localStorage.setItem('userLatest', JSON.stringify(newUserLatest))
    },
    setData() {
      if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
      if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
      if (this.userProfile && this.userProfile.planEndDate < Date.now() || (this.userPlan && this.userPlan.trial && (this.userProfile.trialOverallTime <= 0 || this.userProfile.trialTodayTime <= 0))) {
        this.$router.push('/plans')
      }
      if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
      // if (localStorage.getItem('channelList')) this.eventsList = JSON.parse(localStorage.getItem('channelList'))
      if (localStorage.getItem('leagueList')) {
        this.leaguesList = JSON.parse(localStorage.getItem('leagueList'))
        this.eventsList = []
        this.leaguesList.forEach(league => {
          // Check if the leagueId matches
          if (league.application === "procricket") {
            league.events.forEach(event => {
              this.eventsList.push(event);
            });
          }
        });
      }
    },
    loadLeagues() {
      if (!localStorage.getItem('leagueList')) {
        LeagueService.getLeagueList().then(() => {
          this.leaguesList = JSON.parse(localStorage.getItem('leagueList'))
        });
      }
    },
    loadDynamicData() {
      if (localStorage.getItem('dynamicData')) {
        if (!JSON.parse(localStorage.getItem('dynamicData')).edgeAddress) {
          localStorage.removeItem('dynamicData');
        } else {
          this.dynamicData = JSON.parse(localStorage.getItem('dynamicData'))
        }
      } else {
        UsersService.getDynamicData().then((data) => {
          this.dynamicData = JSON.parse(localStorage.getItem('dynamicData'))
        })
      }
    },
    handleScroll() {
      // Check if the page has been scrolled
      this.scrolled = window.scrollY > 0;
    },
  },
  beforeMount() {
    if (
        localStorage.getItem('userPlan') == null ||
        localStorage.getItem('userProfile') == null ||
        localStorage.getItem('leagueList') == null
    ) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      });
    } else {
      this.loadLeagues();
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
    this.setData()
  },
  mounted() {
    this.getUserLatest();
    this.interval = setInterval(function () {
      this.getUserLatest();
    }.bind(this), 30000);

    window.addEventListener('scroll', this.handleScroll);

  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);

  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
})
</script>

<style>
@import '../assets/css.css';
.user-notification {top: 120px;}
.info-img{margin-right: 10px;}
#error .container-fluid{padding: 0 8%;}
</style>