<template>
  <footer id="footer" class=" py-3 text-white  ps-4">
    <div class="container-fluid"> © {{ new Date().getFullYear() }} Procplayer.tv </div>
    <!--/container-fluid-->
  </footer>
  <!--/footer-->
</template>

<script>
export default ({
    name: 'Footer',
     data() {
    return {
      scrolled: false
    };
  },
  methods: {

  }
})
</script>

<style>
@import '../assets/css.css';
</style>