<template>
  <header id="header" class="fixed-top" :class="{ 'py-4': !scrolled, 'py-2': scrolled }">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-auto me-auto">
          <img @click="goEvents" src="../assets/images/logo-3.svg" alt="logo">
        </div>
        <!--/col()-->
        <div class="col-auto">
          <img class="account-icon" @click="doAccount" src="../assets/images/user-2.svg" alt="icon">
        </div>
        <!--/col()-->
      </div>
      <!--/row-->
    </div>
    <!--/container-fluid-->
  </header>
  <!--/header-->
</template>

<script>
import { defineComponent } from "vue";
import AuthService from "@/services/auth-service";

export default defineComponent({
  name: 'Header',
  data() {
    return {
      scrolled: false,
    }
  },
  methods: {
   goEvents () {
      this.$router.push('/events')
    },
    doAccount() {
      this.$router.push('/account')
    },
    goHome () {
      this.$router.push('/')
    },
     handleScroll() {
      // Check if the page has been scrolled
      this.scrolled = window.scrollY > 0;
    }

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
})
</script>
