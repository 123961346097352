<template>
  <div class="col-lg-12 col-xl-6">
    <div class="card bg-light border-0 rounded-4 text-center h-100">
      <div class="badge bg-white text-danger fs-6 rounded-pill px-4 py-2 mb-0">LIVE</div>
      <a class="card-body p-sm-5 pt-6 text-decoration-none loadingSkeleton">
        <div class="row align-items-center justify-content-between">
          <div class="col-4">
            <img class="img-fluid rounded" src="../assets/images/in.png" alt="img">
            <small class="d-block mt-2 text-uppercase fs-6">PAKISTAN</small>
          </div>
          <!--/col(left)-->
          <div class="col-sm-4 col-3">
            <img class="img-fluid" src="../assets/images/play-icon.svg" alt="icon">
          </div>
          <!--/col(center)-->
          <div class="col-4">
            <img class="img-fluid  rounded" src="../assets/images/pk.png" alt="img">
            <small class="d-block mt-2 text-uppercase fs-6">PAKISTAN</small>
          </div>
          <!--/col(right)-->
        </div>
        <!--/row-->
        <hr class="my-sm-4">
        <p class="mb-0">You may use special comments to disable some warnings.</p>
      </a>
      <!--/card-body-->
    </div>
    <!--/card-->
  </div>
  <!--/col()-->
</template>
<script>
export default {
  // You can add props, data, or methods as needed
};
</script>
    <style scoped>
    
    /* Add styles for skeleton loading */
small,p,div {color: #e5e5e5 !important;}
.badge,small,p{background: #e5e5e5 !important;}
img,#content .card:hover a img[alt="icon"]{filter: brightness(0) invert(0.90) !important; transform: scale(1) !important;}
#content .card a:after{background: #f1f1f1;}
@keyframes bgAnimate {
  0% {background-position: 50% 0;}
  100% {background-position: -150% 0;}
}

.loadingSkeleton {
  height: v-bind(height);
  width: v-bind(width);
  background-image: linear-gradient(
    to right,
    #f9f9f9 0%,
    #f2f2f2 10%,
    #f1f1f1 20%,
    #f1f1f1 100%
  );
  background-size: 200% 100%;
  animation: bgAnimate 1.5s linear infinite;
  border-radius: var(--bs-border-radius-xl) !important;
}
    </style>  